import { PlantLifeCycle, PlantType } from "../../../../../api/types/plants";
import { CafsType } from "../../../../../consts/cafsType";
import { DataFormat } from "../../../../../consts/data-format";
import { CafsVariable } from "../../../../../types/cafs-variable";
import { CafsVariableType } from "../../../const/cafs-variable-type";
import { renderForTree } from "../../../utils/render-for-tree";

export function useLifecycleVariables(
  cafsType: PlantType | undefined,
): CafsVariable<PlantLifeCycle>[] {
  return [
    {
      name: "LifeCycleRemarks",
      type: CafsVariableType.Remarks,
    },
    {
      name: "NaturalLifeCycle",
      type: CafsVariableType.Input,
      format: cafsType === CafsType.Tree ? DataFormat.Year : undefined,
    },
    {
      name: "EconomicLifeCycle",
      type: CafsVariableType.Input,
      format: cafsType === CafsType.Tree ? DataFormat.Year : undefined,
    },
    {
      name: "TreeThinningYears",
      type: CafsVariableType.Input,
      text: true,
      shouldRender: renderForTree,
    },
    {
      name: "TreeThinningFractions",
      type: CafsVariableType.Input,
      shouldRender: renderForTree,
      text: true,
    },
    {
      name: "MortalityAgeYears",
      type: CafsVariableType.Input,
      shouldRender: renderForTree,
      text: true,
    },
    {
      name: "MortalityFractions",
      type: CafsVariableType.Input,
      shouldRender: renderForTree,
      text: true,
    },
    {
      name: "Rotations",
      type: CafsVariableType.Input,
      shouldRender: renderForTree,
    },
    {
      name: "YearsBetweenPlanting",
      type: CafsVariableType.Input,
      shouldRender: renderForTree,
    },
  ];
}
