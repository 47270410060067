import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { Datasets } from "../../../api/datasets";
import { MutationKey } from "../../../consts/mutation-keys";
import { QueryKey } from "../../../consts/query-keys";
import { useApiError } from "../../../hooks/useApiError";
import { useApiSuccess } from "../../../hooks/useApiSuccess";
import { ApiHookConfig } from "../../../types/api-hook-config";

export function useDatasetCopy({ onSuccess }: ApiHookConfig) {
  const { sourceId, targetId } = useParams();
  const { formatMessage } = useIntl();

  const { apiErrors, clearApiErrors, handleError } = useApiError();
  const { handleSuccess } = useApiSuccess({
    invalidate: [
      [QueryKey.DataSetsCollection, sourceId!, targetId!],
      [QueryKey.Regions],
    ],
    snackbarText: formatMessage({
      id: "ACTION.DATASET_COPY",
      defaultMessage: "Dataset has been copied successfully.",
      description: "Dataset copy success message",
    }),
    onSuccessFn: onSuccess,
  });

  const mutationFn = (data: { sourceId: number; targetId: number }) => {
    return Datasets.copy({
      path: { sourceId: data.sourceId, targetId: data.targetId },
    });
  };

  const { mutate: copyDataset } = useMutation({
    mutationKey: [MutationKey.CreateCafsGuest, sourceId, targetId],
    mutationFn: mutationFn,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { copyDataset, apiErrors, clearApiErrors };
}
