import { ReactNode } from "react";
import * as React from "react";

import { Help } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import clsx from "clsx";

import { StringCafsValue } from "../../../../api/types/cafs";
import { TooltipItem } from "../../../../api/types/tooltips";
import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../../components/rhf/RhfInput";
import { TextWithEllipsis } from "../../../../components/TextWithEllipsis";
import { PlantDetailsForm } from "../../../../types/plant-details-form";

export type CafsRowRowProps = {
  data: PlantDetailsForm;
  name: keyof PlantDetailsForm;
  isReadMode: boolean | undefined;
  tooltip?: TooltipItem;
  rows?: number;
};

export function PlantFormRow({
  data,
  name,
  tooltip,
  isReadMode,
  rows,
}: CafsRowRowProps) {
  return (
    <TableRow
      className={clsx({
        draftRow: !!(data[name] as StringCafsValue)?.Base,
      })}>
      {/* name */}
      <TableCell sx={{ width: "30%" }}>
        <div className="flex flex-row items-center">
          <div className="flex flex-col">
            <span className="font-mono">{name as ReactNode}</span>
            {!!(data[name] as StringCafsValue)?.Base && (
              <small className="text-orange-500">Edited</small>
            )}
          </div>

          {tooltip && tooltip.Description && (
            <Tooltip title={tooltip.Description}>
              <IconButton className="hover:text-primary">
                <Help />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </TableCell>

      {/* EnglishName */}
      <TableCell sx={{ width: "70%" }}>
        <RhfFormField name={`${name}`}>
          <RhfInput
            label={name}
            name={`${name}.Base`}
            multiline={!!rows}
            rows={rows ? rows : 1}
            type="text"
            disabled={isReadMode}></RhfInput>
        </RhfFormField>

        {(data[name] as StringCafsValue)?.Base && (
          <p className="mt-2">
            <span className="font-semibold text-shark">Current value:</span>{" "}
            <TextWithEllipsis
              text={(data[name] as StringCafsValue)?.Current ?? ""}
              limit={100}
            />
          </p>
        )}
      </TableCell>
    </TableRow>
  );
}
