import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { FileCopy } from "@mui/icons-material";
import { Button } from "@mui/material";

import { ModalSize, MuiModal } from "../../../components/mui/MuiModal";
import { useModal } from "../../../hooks/useModal";
import { useDatasetCopy } from "../api/useDatasetCopy";

import { DatasetCopyForm } from "./DatasetCopyForm";

export function DatasetCopy() {
  const methods = useForm();
  const { open, handleOpen, handleClose } = useModal();
  const { copyDataset } = useDatasetCopy({ onSuccess: handleClose });

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<FileCopy />}
        onClick={() => handleOpen()}>
        Copy dataset
      </Button>

      <MuiModal
        open={open}
        onClose={handleClose}
        size={ModalSize.Medium}
        header="Copy dataset">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(data => {
              copyDataset({
                sourceId: data.sourceId,
                targetId: data.targetId,
              });
            })}>
            <DatasetCopyForm />
          </form>
        </FormProvider>
      </MuiModal>
    </>
  );
}
