import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";

import { ModalSize, MuiModal } from "../../../components/mui/MuiModal";
import { useModal } from "../../../hooks/useModal";
import { useDatasetCreate } from "../api/useDatasetCreate";

import { DatasetCreateForm } from "./DatasetCreateForm";

export function DatasetCreate() {
  const methods = useForm();
  const { open, handleOpen, handleClose } = useModal();
  const { createDataset } = useDatasetCreate({ onSuccess: handleClose });

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Add />}
        onClick={() => handleOpen()}>
        Add new dataset
      </Button>

      <MuiModal
        open={open}
        onClose={handleClose}
        size={ModalSize.Medium}
        header="Add new dataset">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(data => {
              console.log("data", data);
              createDataset(data);
            })}>
            <DatasetCreateForm />
          </form>
        </FormProvider>
      </MuiModal>
    </>
  );
}
