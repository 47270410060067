import * as React from "react";
import { ReactNode, useContext } from "react";
import { useFormContext } from "react-hook-form";

import { Help } from "@mui/icons-material";
import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import clsx from "clsx";
import DOMPurify from "dompurify";

import { CafsValue } from "../../../../api/types/cafs";
import { TooltipItem } from "../../../../api/types/tooltips";
import { Formula } from "../../../../components/formula/Formula";
import { RhfFormField } from "../../../../components/rhf/RhfFormField";
import { RhfInput } from "../../../../components/rhf/RhfInput";
import { TextWithEllipsis } from "../../../../components/TextWithEllipsis";
import { DataFormat } from "../../../../consts/data-format";
import { Status } from "../../../../consts/status";
import { useHighlight } from "../../../../hooks/useHiglight";
import { CalculationFn } from "../../../../types/calculation-fn";
import { emptyFn } from "../../../../utils/empty-fn";
import { getCorrectUnit } from "../../../../utils/get-correct-unit";
import {
  getBaseValue,
  getCurrentValue,
  getParentValue,
} from "../../../../utils/variable-helper";
import { CafsContext } from "../../CafsContext";
import { CafsSection } from "../../const/cafs-section";
import { ReferenceAssign } from "../ReferenceAssign";

import { CalculatedField } from "./CalculatedField";

export type InputRowProps = {
  variableName: string;
  variableValue: CafsValue;
  sectionName: CafsSection;
  productCode?: string;
  guestName?: string | null;
  tooltip?: TooltipItem;
  data?: unknown;
  dataFormat?: DataFormat;
  dependOn?: Array<string>;
  calculationFn?: CalculationFn;
  description?: string;
  disabled?: boolean;
  type?: React.InputHTMLAttributes<unknown>["type"];
  focusOnError?: boolean;
  hidden?: ["Parent" | "New Base" | "Calculated" | "Current" | ""];
};

export function InputRow({
  variableName,
  data,
  variableValue,
  dependOn,
  calculationFn = emptyFn,
  sectionName,
  disabled,
  tooltip,
  dataFormat,
  productCode,
  guestName,
  type = "number",
  hidden = [""],
}: InputRowProps) {
  const { cafs } = useContext(CafsContext);
  const { watch, getFieldState } = useFormContext();

  const { addHighlight, removeHighlight } = useHighlight(
    dependOn ?? [],
    sectionName,
  );

  const base = watch(`${variableName}.Base`);
  const isDirty = getFieldState(`${variableName}.Base`).isDirty;
  const isCalculatedRow = !!dependOn?.length;
  const renderCalculated = !hidden.includes("Calculated");
  const displayTooltip = tooltip && tooltip.Description;
  const sanitizedDescription = DOMPurify.sanitize(tooltip?.Description ?? "");

  const displayEditLabel =
    getBaseValue(variableValue) !== null &&
    getBaseValue(variableValue) !== undefined &&
    cafs?.Status === Status.DraftEdit;

  const className = clsx({
    draftRow:
      (isDirty && base?.length) ||
      (getBaseValue(variableValue) !== null &&
        getBaseValue(variableValue) !== undefined &&
        cafs?.Status === Status.DraftEdit),
  });

  return (
    <TableRow
      sx={{ height: "85px" }}
      id={`${sectionName}_${variableName}`}
      key={`${variableName}`}
      data-testid={`VariableRow.${variableName}`}
      className={className}
      onMouseEnter={addHighlight}
      onMouseLeave={removeHighlight}>
      <TableCell>
        <div className="flex flex-col" data-testid={`${variableName}.name`}>
          <p className="font-mono">{variableName as ReactNode}</p>
          {displayEditLabel && (
            <small
              className="text-orange-400 italic"
              data-testid="row-edited-label">
              Edited
            </small>
          )}
        </div>
      </TableCell>

      <TableCell>
        {displayTooltip && (
          <Tooltip
            title={
              <span
                dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
              />
            }
            data-testid={`${variableName}.tooltip`}>
            <IconButton className="hover:text-primary">
              <Help />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      <TableCell>
        {tooltip?.Unit ? (
          <TextWithEllipsis text={getCorrectUnit(cafs, tooltip)} limit={10} />
        ) : (
          ""
        )}
      </TableCell>

      <TableCell>
        <div data-testid={`${variableName}.parent`}>
          <Formula format={dataFormat} value={getParentValue(variableValue)} />
        </div>
      </TableCell>

      {/* Base */}

      <TableCell>
        {!isCalculatedRow && (
          <RhfFormField name={`${variableName}`}>
            <RhfInput
              disabled={disabled}
              type={type}
              focusOnError
              name={`${variableName}.Base`}
              label={variableName as ReactNode}
              inputProps={{ "data-testid": `${variableName}.base` }}
            />
          </RhfFormField>
        )}
      </TableCell>

      {renderCalculated && (
        <TableCell>
          <div className="pointer-events-none hidden text-right">
            {isCalculatedRow && (
              <RhfFormField name={`${variableName}.Calculated`}>
                <RhfInput
                  type={type}
                  name={`${variableName}.Calculated`}
                  readonly
                />
              </RhfFormField>
            )}
          </div>
          <div
            data-testid={`${variableName}.calculated`}
            className="text-right">
            <CalculatedField
              dependencies={dependOn || []}
              field={variableName}
              data={data}
              fn={calculationFn}
              format={dataFormat}
            />
          </div>
        </TableCell>
      )}

      {/* Current value */}

      <TableCell>
        <div className="pointer-events-none hidden">
          <RhfFormField name={`${variableName}.Current`}>
            <RhfInput type={type} name={`${variableName}.Current`} readonly />
          </RhfFormField>
        </div>
        <div data-testid={`${variableName}.current`} className="text-right">
          <Formula format={dataFormat} value={getCurrentValue(variableValue)} />
        </div>
      </TableCell>

      <TableCell>
        <ReferenceAssign
          fieldName={variableName}
          sectionName={sectionName}
          productCode={productCode}
          guestName={guestName}
        />
      </TableCell>
    </TableRow>
  );
}
