import React, { useContext } from "react";
import { useIntl } from "react-intl";

import { AppContext } from "../../AppContext";
import { PageHeader } from "../../components/PageHeader";
import { PageSection } from "../../components/PageSection";
import { RenderByRole } from "../../components/RenderByRole";
import { UserRole } from "../../consts/user-role";

import { useIsProduction } from "./api/useIsProduction";
import { DatasetCopy } from "./components/DatasetCopy";
import { DatasetCreate } from "./components/DatasetCreate";
import { DatasetImport } from "./components/DatasetImport";
import { DatasetsTable } from "./components/DatasetsTable";
import { DeletedItems } from "./components/DeletedItems";
import { JsonUpload } from "./components/JsonUpload";

export function DatasetView() {
  const { user } = useContext(AppContext);
  const { isProduction } = useIsProduction(
    user?.Role === UserRole.FarmTreeDeveloper,
  );
  const { formatMessage } = useIntl();

  return (
    <>
      <PageHeader
        text={formatMessage({
          id: "DATASET.TITLE",
          defaultMessage: "Dataset",
          description: "Dataset page title",
        })}
        rightPart={
          <div className="flex gap-2">
            <RenderByRole
              role={user?.Role}
              allowedRoles={[
                UserRole.FarmTreeMaster,
                UserRole.FarmTreeDeveloper,
              ]}>
              <DatasetCreate />
              <DatasetCopy />
            </RenderByRole>
            <DeletedItems />
          </div>
        }
      />

      {user?.Role === UserRole.FarmTreeDeveloper && (
        <PageSection
          title={formatMessage({
            id: "DASHBOARD.TITLE",
            defaultMessage: "Upload CSV file",
          })}>
          <DatasetImport />
        </PageSection>
      )}

      {user?.Role === UserRole.FarmTreeDeveloper && (
        <PageSection
          title={formatMessage({
            id: "JSON_DOWNLOAD.TITLE",
            defaultMessage: "Download | Upload JSON",
          })}>
          <JsonUpload isProduction={isProduction} />
        </PageSection>
      )}

      <PageSection
        title={formatMessage({
          id: "DATASET.REGIONS",
          defaultMessage: "Region and owner assignment",
          description: "Dataset region assignment section title",
        })}
        description={formatMessage({
          id: "DATASET.REGIONS_SUBTITLE",
          description: "Dataset region assignment section subtitle",
          defaultMessage: "Assign regions and owners to datasets.",
        })}>
        <DatasetsTable />
      </PageSection>
    </>
  );
}
